import * as React from 'react';
import { useState } from 'react';
import { useEffect  } from 'react';
import { useSelector } from 'react-redux';
const contactBotData = {title: 'Don’t hesitate to contact us and <br/> we’ll create a piece of art for you.', link: '/contact'}
function ContactBot() {
  const lang = useSelector((state) => state.astronet.lang) || 'vi';
  const translate = useSelector((state) => state.astronet.translate);

  return (
      <section className="contact-us">
            <p dangerouslySetInnerHTML={{__html: translate[lang].contact_bottom}}></p>
            <a className="btn" href="/contact">{ translate[lang].talk_to_us_now}</a>
        </section>
  );
}

export default ContactBot;