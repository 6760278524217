import * as React from "react";
import { useEffect, useState } from "react";
import "../css/contact.css";
import { api } from "../utils/api";
import { useForm } from "react-hook-form";
import { emailPattern } from "../utils/config";
import { useSelector } from "react-redux";
function Contact() {
  const [issues, setIssues] = useState([]);
  const [contact, setContact] = useState({})

  const lang = useSelector(state => state.astronet.lang)
  const translate = useSelector((state) => state.astronet.translate);
  useEffect(() => {
    api({ path: "/page-default/contact" })
      .then((r) => r.json())
      .then((r) => {
        if (r.success && r.data) {
          setContact(r.data)
        }
      });
    api({ path: "/config?fields=contactIssues" })
      .then((res) => res.json())
      .then((res) => {
        console.log({ res });
        if (res.success) {
          if (res.data && res.data[0]) {
            setIssues(res.data[0].contactIssues);
          }
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const onSubmit = (data) => {
    api({
      path: "/contact",
      method: "POST",
      body: {...data,lang: lang}
    }).then(r => r.json()).then(r => {
      if(r.success) {
        alert('Đã gửi thành công')
      }else{
        alert('Có lỗi xảy ra. Vui lòng thử lại')
      }
    });
  };

  return (
    <main id="main" className="content-contact">
      <div className="banner-contact">
        <div className="container">
          <div className="box-banner-contact">
            <div className="cus-review">
             {contact && contact.banner && contact.banner.subImages.map((img, index) => <div className="review-item">
                <img
                  className="avatar-cus"
                  src={img.url}
                  alt=""
                />
                <p className="content-text">
                {img.text}
                </p>
              </div>) 
}
              {/* <div className="review-item">
                <img
                  className="avatar-cus"
                  src="/images/contact/avatar2.svg"
                  alt=""
                />
                <p className="content-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Lobortis nec,
                </p>
              </div>
              <div className="review-item">
                <img
                  className="avatar-cus"
                  src="/images/contact/avatar3.svg"
                  alt=""
                />
                <p className="content-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Lobortis nec,
                </p>
              </div> */}
              <h2 className="on-sp">{translate[lang].let_us_reach_u}</h2>
            </div>
            <div className="title-banner">
              <h1>{contact.title}</h1>
              <div className="follow-social">
                <h4>Follow us on social media</h4>
                <ul>
                  <li>
                    <a href="#">
                      <img src="/images/about/logo-twitter.svg" alt="social" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="/images/about/logo-facebook.svg" alt="social" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src="/images/about/logo-instagram.svg"
                        alt="social"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="/images/about/logo-linkedin.svg" alt="social" />
                    </a>
                  </li>
                </ul>
                <h2 className="on-pc">{translate[lang].let_us_reach_u}</h2>
              </div>
            </div>
          </div>
          <div className="form-contact">
            <div className="box-form-contact">
              <form className="custom-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group form-flex">
                  <div
                    className="wrapper-input-form"
                    style={{ marginRight: "1.111vw", width: "100%" }}
                  >
                    <input
                      type="text"
                      placeholder="Your name"
                      className="cus-form-50 cus-form-style"
                      {...register("name", { required: true })}
                    />
                    {errors.name && errors.name.type == "required" && (
                      <p className="p-error">{translate[lang].name_required}</p>
                    )}
                  </div>
                  <div
                    className="wrapper-input-form"
                    style={{ marginRight: "1.111vw", width: "100%" }}
                  >
                    <input
                      type="text"
                      placeholder="Your email"
                      className="cus-form-50 cus-form-style"
                      {...register("email", {
                        required: false,
                        pattern: emailPattern,
                      })}
                    />
                    {errors.email && errors.email.type == "pattern" && (
                      <p className="p-error">{translate[lang].email_invalid}</p>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <div className="wrapper-input-form">
                    <select className="cus-form-style" {...register("issue")}>
                      <option defaultChecked key={-1} value="-1">
                        {translate[lang].u_talk_about}
                      </option>
                      {issues.map((issue, index) => (
                        <option key={index} value={issue._id}>
                          {issue.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <div className="wrapper-input-form">
                    <textarea
                      placeholder="Your message"
                      className="cus-form-style"
                      {...register("message", { required: true })}
                    ></textarea>
                    {errors.message && errors.message.type == "required" && (
                      <p className="p-error">{translate[lang].message_required}</p>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <button className="btn" type="submit">
                    {translate[lang].form_submit}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Contact;
