import * as React from 'react';
import MenuList from '../components/Menu';
import {useSelector} from 'react-redux'
function Footer({logo}) {
    const menu = useSelector(state => state.astronet.footerMenu)
    const copyright = useSelector(state => state.astronet.copyrightText)
  return (
      <footer id="footer">
        <div className="container">
            <div className="box-footer list-flex">
            <div className="menu-footer on-pc">
                    <ul>
                        <MenuList/>
                    </ul>
                </div>
                <a href="#" className="logo"><img src={logo || "/images/logo.svg"} alt="img"/></a>
                <div className="menu-footer on-sp">
                    <ul>
                        <MenuList/>
                    </ul>
                </div>
                <div className="rules">
                    {menu.map((data, index) => <a key={index} href={data.href}>{data.label}</a> )}
                </div>
            </div>
            <div className="copyright">
                <p>{copyright}</p>
            </div>
        </div>

    </footer>
  );
}

export default Footer;
