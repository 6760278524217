import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./css/header.css";
import "./css/footer.css";
import "./css/content.css";
import "./css/general.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import reduxReducer, { setLang } from "./redux";
import App from "./App";
const store = configureStore({
  reducer: {
    astronet: reduxReducer,
  },
});
const root = ReactDOM.createRoot(document.getElementById("root"));
const lang = window.localStorage.getItem("lang");
const query = new URLSearchParams(window.location.search);
console.log({ query: query.get("lang") });
if (query.get("lang") && ["vi", "en"].indexOf(query.get("lang")) !== -1) {
  window.localStorage.setItem("lang", query.get("lang"));
  store.dispatch(setLang(query.get("lang")));
} else if (!lang) {
  window.localStorage.setItem("lang", "vi");
  store.dispatch(setLang(lang));
}else store.dispatch(setLang(lang));

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
