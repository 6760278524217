import * as React from 'react';
import MenuList from '../components/Menu';
function Header({logo}) {
  return (
    <header id="header">
        <div className="container">
            <div className="box-header">
                <a href="/" className="logo">
                    <img src={logo || "/images/logo.svg"} alt="logo"/>
                </a>
                <div className="menu-main">
                    <ul>
                        <MenuList/>
                    </ul>
                </div>
                {/* <div className="menu-bar on-sp">
                    <span className="bar"></span>
                    <span className="bar"></span>
                    <span className="bar"></span>
                </div> */}
            </div>
        </div>
    </header>
  );
}

export default Header;
