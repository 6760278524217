import * as React from "react";
import "../css/career.css";
import ContactBot from "../components/ContactBot";
import Slide from "../components/SlideBot";
import CareerStories from "../components/CareerStories";
import CareerListJobs from "../components/CareerListJobs";
import { useEffect } from "react";
import { api } from "../utils/api";
import { useSelector } from "react-redux";
import { useState } from "react";
function Career() {
  const [career, setCareer] = useState({ items: [] });
  const [job, setJob] = useState({ jobData: { items: [] } });
  const [internalArticle, setInternalArticle] = useState({
    internalArticleItems: [],
  });
  const [post, setPost] = useState([]);
  const lang = useSelector((state) => state.astronet.lang);
  useEffect(() => {
    // api({path: `/information/${lang}?types=career,job,internal_article`}).then(r => r.json()).then(r => {
    //     let data = r.data || []
    //     let career = data.find(d => d.type === 'career')
    //     let job = data.find(d => d.type === 'job')
    //     let iArticle = data.find(d => d.type === 'internal_article')
    //     if(career)  setCareer(career.careerData)
    //     if(job)  setJob(job)
    //     if(iArticle) setInternalArticle(iArticle)
    // })
    api({ path: "/page-default/career" })
      .then((r) => r.json())
      .then((r) => {
        if (r.success && r.data) {
          console.log(r.data.sections);
          let data = r.data.sections || [];
          let career = data.find((d) => d.type === "career");
          let job = data.find((d) => d.type === "job");
          let iArticle = data.find((d) => d.type === "internal_article");
          if (career) setCareer(career.careerData);
          if (job) setJob(job);
          if (iArticle) setInternalArticle(iArticle);
        }
      });
    api({ path: "/article" })
      .then((r) => r.json())
      .then((r) => {
        console.log(1111, r.data);
        if (r.success) setPost(r.data);
      });
  }, []);
  return (
    <main id="main" className="content-career">
      <section className="section-introduce">
        <div className="container">
          <div className="content-text">
            <h1 className="title-large">{career.title}</h1>
            <p>{career.description}</p>
          </div>
          <div className="list-content">
            {career.items && <CareerStories items={career.items} />}
          </div>
        </div>
      </section>
      <section className="section-jobs">
        <div className="container">
          <div className="content-container">
            <div className="title-list-job">
              <h2 className="title-green">{job.name}</h2>
              <h3 className="title-large">{job.title}</h3>
            </div>
          </div>
        </div>
        <div className="list-job-detail">
          <div className="container">
            <div className="content-container">
              <p className="des">{job.description} </p>
              <div className="list-jobs">
                {job.jobData && job.jobData.items && (
                  <CareerListJobs items={job.jobData.items} />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {internalArticle && <Slide data={internalArticle} items={post} />}
      <ContactBot />
    </main>
  );
}

export default Career;
