import React, { useEffect,  useState } from "react";
import {useParams} from "react-router-dom";
import { api } from "../utils/api";
import Home from "./Home";
function ChildPage() {
    const {page} = useParams();
    const [dataPage, setDatePage] = useState({})
    console.log({page})
    useEffect(() =>{
        api({path: '/page-custom/'+page}).then(r => r.json()).then(r => {
            console.log({r})
            if(r.code && r.code == 'DATA_NOT_FOUND') console.log('redirect to 404') 
            if(r.success) {
                setDatePage(r.data)
            }
        })
    },[])
    
    return <>
    {
        dataPage.type === 'home_custom' && <Home prop={dataPage} />
    }
    </>
}
export default ChildPage