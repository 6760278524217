import * as React from "react";
import ContactBot from "../components/ContactBot";
import AboutOffice from "../components/AboutOffice";

import "../css/about.css";
import { useEffect } from "react";
import { api } from "../utils/api";
import { useSelector } from "react-redux";
import { useState } from "react";

import * as DOMPurify from "dompurify";

function About() {
  const [about, setAbout] = useState({})
  const [team, setTeam] = useState({ images: [] });
  const [career, setCareer] = useState({});
  const [location, setLocation] = useState({ officeItems: [] });
  const [socials, setSocials] = useState({
    facebook: "#",
    instagram: "#",
    linkedin: "#",
    twitter: "#",
  });
  const lang = useSelector((state) => state.astronet.lang) || "vi";
  const translate = useSelector((state) => state.astronet.translate);
  useEffect(() => {
    // api({ path: `/information/${lang}?types=team,career,location` })
    //   .then((r) => r.json())
    //   .then((r) => {
    //     let data = r.data || [];
    //     let team = data.find((d) => d.type === "team")
    //     let career = data.find((d) => d.type === "career")
    //     let location = data.find((d) => d.type === "location")
    //     team && setTeam(team);
    //     career && setCareer(career);
    //     location && setLocation(location);
    //   });
    api({ path: "/page-default/about" })
      .then((r) => r.json())
      .then((r) => {
        if (r.success && r.data) {
          console.log(r.data.sections);
          setAbout(r.data)
          let data = r.data.sections || [];
          let team = data.find((d) => d.type === "team");
          let career = data.find((d) => d.type === "career");
          let location = data.find((d) => d.type === "location");
          team && setTeam(team);
          career && setCareer(career);
          location && setLocation(location);
        }
      });
    api({ path: `/config/${lang}?fields=socials` })
      .then((r) => r.json())
      .then((r) => {
        let data = r.data || [];
        data[0] && setSocials(data[0].socials);
      });
  }, []);
  return (
    <main id="main" className="content-about">
      <section className="home-banner">
        <div className="container">
          <div className="box-home-banner list-flex">
            <div className="right on-pc">
              <img
                src={about.banner  ?about.banner.image : '/images/about/person.png'}
                alt=""
                className="future-image"
              />
              {
                about.banner && about.banner.subImages&& about.banner.subImages.map((img , i) =>  <div className={`brand${i+1} brand`}>
                <a href={img.link}>
                  <img src={img.url} alt="" />
                </a>
              </div>)
              }
              
              <img src="/images/home/ic.svg" alt="" className="ic" />
            </div>
            <div className="left">
              <span dangerouslySetInnerHTML={{__html: about.title}}></span>
              <div
                className="des-banner"
                dangerouslySetInnerHTML={{ __html: about.description }}
              ></div>
            </div>
            <div className="right on-sp">
              <img
                src={about.banner  ?about.banner.image : '/images/about/person.png'}
                alt=""
                className="future-image"
              />
              {
                about.banner && about.banner.subImages&& about.banner.subImages.map((img , i) =>  <div className={`brand${i+1} brand`}>
                <a href={img.link}>
                  <img src={img.url} alt="" />
                </a>
              </div>)
              }
              
              <img src="/images/home/ic.svg" alt="" className="ic" />
            </div>
          </div>
        </div>
      </section>
      <section className="content-home">
        <div className="content-item content-item1">
          <div className="content-container list-flex">
          <div className="content-text">
            <h2>{team.name}</h2>
            <h3>{team.title}</h3>
            <p>{team.content}</p>
            <h4>{translate[lang].follow_social}</h4>
            <ul>
              <li>
                <a target="_blank" href={socials.twitter}>
                  <img src="/images/about/logo-twitter.svg" alt="social" />
                </a>
              </li>
              <li>
                <a target="_blank" href={socials.facebook}>
                  <img src="/images/about/logo-facebook.svg" alt="social" />
                </a>
              </li>
              <li>
                <a target="_blank" href={socials.instagram}>
                  <img src="/images/about/logo-instagram.svg" alt="social" />
                </a>
              </li>
              <li>
                <a target="_blank" href={socials.linkedin}>
                  <img src="/images/about/logo-linkedin.svg" alt="social" />
                </a>
              </li>
            </ul>
          </div>
          <div className="content-img">
            {team.images.map((img, i) => (
              <img key={i} src={img.url} alt="" />
            ))}
          </div>
          </div>
        </div>
        <div className="content-item content-item2">
          <div className="content-container list-flex">
          <div className="content-text">
            <h2>{career.name}</h2>
            <h3>{career.title}</h3>
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(career.description),
              }}
            ></span>
            <a href="#" className="btn">
              {translate[lang].see_more}
            </a>
          </div>

          <div className="content-img">
            <img src="/images/about/earth.png" alt="" />
          </div>
          </div>
        </div>
        <div className="content-item content-item3 ">
        <div className="content-container">
          <div className="content-text">
            <h2>{location.name}</h2>
            <h3>{location.title}</h3>
            <p>{location.content}</p>
          </div>
          <div className="content-address">
            <ul>
              <AboutOffice data={location.officeItems} />
            </ul>
            <a href="#" className="btn"></a>
          </div>
          </div>
        </div>
      </section>
      <ContactBot />
    </main>
  );
}

export default About;
