import * as React from "react";
import { useEffect, useState } from "react";
import ContactBot from "../components/ContactBot";
import ContentItem from "../components/HomeContentItem";
import ContentListAchievements from "../components/HomeListAchievements";
import ContentListBrands from "../components/HomeListBrands";
import { api } from "../utils/api";
import { useSelector } from "react-redux";

function Home({ prop = null }) {
  const [contentItems, setContentItems] = useState([]);
  const translate = useSelector((state) => state.astronet.translate);
  const [brand, setBrand] = useState({});
  const [home, setHome] = useState({});
  const [achievement, setAchievement] = useState({ achievementItems: [] });
  const lang = useSelector((state) => state.astronet.lang) || "vi";
  useEffect(() => {
    if (prop) {
      setHome(prop);
      setAllData(prop.sections);
    } else
      api({ path: "/page-default/home" })
        .then((r) => r.json())
        .then((r) => {
          if (r.success && r.data) {
            console.log(r.data.sections);
            setHome(r.data);
            let data = r.data.sections || [];
            setAllData(data);
          }
        });
  }, []);
  const setAllData = (data) => {
    let content = data.filter(
      (d) => ["mission", "vission"].indexOf(d.type) !== -1
    );
    let brand = data.find((d) => d.type == "brand");
    let achievement = data.find((d) => d.type == "achievement");
    content && setContentItems(content);
    brand && setBrand(brand);
    achievement && setAchievement(achievement);
  };
  return (
    <main id="main">
      <section className="home-banner">
        <div className="container">
          <div className="box-home-banner list-flex">
            <div className="left">
              <span dangerouslySetInnerHTML={{ __html: home.title }}></span>
              {/* <h1>
                Astronet <br />
                <span>Media</span>
              </h1> */}
              <p dangerouslySetInnerHTML={{ __html: home.description }}></p>
            </div>
            <div className="right">
              {home &&
                home.banner &&
                home.banner.subImages &&
                home.banner.subImages.map((img, i) => (
                  <div className={`brand${i + 1} brand`}>
                    <a href={img.link}>
                      <img src={img.url} alt="" />
                    </a>
                  </div>
                ))}
              {/* <div className="brand1 brand">
                <a href="#">
                  <img src="/images/home/logo-binreport.svg" alt="" />
                </a>
              </div>
              <div className="brand2 brand">
                <a href="#">
                  <img src="/images/home/logo-coinnerd.svg" alt="" />
                </a>
              </div>
              <div className="brand3 brand">
                <a href="#">
                  <img src="/images/home/healthcanal.svg" alt="" />
                </a>
              </div> */}
              <img src="/images/home/arrow.svg" alt="" className="arrow" />
              <img src="/images/home/ic.svg" alt="" className="ic" />
              <img
                src="/images/home/block.svg"
                alt=""
                className="banner-block"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="content-home">
        {contentItems.map((content, i) => (
          <ContentItem key={i} data={content} index={i} />
        ))}
        <div className="content-item content-item3 ">
          <div className="content-container">
            <div className="content-text">
              <h2>{brand.name}</h2>
              <h3>{brand.title}</h3>
            </div>
            <div className="content-brand">
              <div className="container">
                {/* <ul> */}
                <ContentListBrands brands={brand.images} />
                {/* </ul> */}
              </div>
            </div>
          </div>
        </div>
        <div className="content-item content-item4 ">
          <div className="content-container">
            <div className="content-text">
              <h2>{achievement.name}</h2>
              <h3>{achievement.title}</h3>
            </div>
            <div className="content-statistic">
              <ul>
                <ContentListAchievements data={achievement.achievementItems} />
              </ul>
            </div>
          </div>
        </div>
      </section>
      <ContactBot />
    </main>
  );
}

export default Home;
