export const api = async (args) => {
    return fetch(`https://api.astronetmedia.com/v1/api${args.path}`, {
        method: args.method || 'GET',
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: args.body ? JSON.stringify(args.body) : undefined
    })
}
