import * as React from "react";
import {  useSelector } from 'react-redux'

function Menu() {
  const menu = useSelector(state => state.astronet.mainMenu)
  return (
    <>
      {menu.map((content, index) => (
        <li key={index}>
          <a href={content.href}>{content.label}</a>
        </li>
      ))}
    </>
  );
}

export default Menu;
