import * as React from "react";
import SlideBotItems from "./SlideBotItems";

function SlideBot({ data, items }) {
  return (
    <section className="internal-article">
      <div className="content-container ">
        <div className="description-list-post">
          <h2 className="title-green">{data.name}</h2>
          <h3 className="title-large">{data.title}</h3>
          <p>{data.description}</p>
        </div>
        <div className="list-post">
          {/* <div className="slider-list"> */}
          {items && <SlideBotItems items={items}></SlideBotItems>}
          {/* </div> */}
        </div>
      </div>
    </section>
  );
}

export default SlideBot;
