import * as React from 'react';

function CareerListJob({items}) {
    return (
      <>
        { 
            items.map((content, i) =>  
            <div key={i} className="job-item">
                <a href={content.link || '#'} className="layer-mark"></a>
                <h3>{content.title}</h3>
                <p>{content.description}</p>
            </div>
            )
        }
      </>
    );
}
export default CareerListJob;