import * as React from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";

function SlideBotItem({ items }) {
  const lang = useSelector((state) => state.astronet.lang) || 'vi';
  const translate = useSelector((state) => state.astronet.translate);
  const setting = {
    slidesToShow: items.length > 1 ? 2 : 1,
    infinite: true,
    autoplay: false,
    slidesToScroll: 1,
    centerPadding: "144px 0px 0px",
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: items.length > 1 ? 3 : 1,
          centerPadding: "50px",
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: items.length > 1 ? 2 : 1,
          centerPadding: "20px",
        },
      },
    ],
  };
  return (
    <Slider {...setting}>
      {items.map((content, i) => (
        <div key={i} className="list-item">
          <img className="im-future" src={content.image || 'https://t3.ftcdn.net/jpg/04/84/88/76/360_F_484887682_Mx57wpHG4lKrPAG0y7Q8Q7bJ952J3TTO.jpg'} alt="" />
          <h3>{content.title}</h3>
          <p>{content.description}</p>
          <a href={content.link} className="title-green">
            Readmore <img src="/images/career/btn-more.svg" alt="" />
          </a>
        </div>
      ))}
    </Slider>
  );
}

export default SlideBotItem;
