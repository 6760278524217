import * as React from "react";
function ContentItem({ data, index }) {
  return (
    <div className={"content-item content-item" + (index + 1) + ""}>
       <div className="content-container list-flex">
      <div className="content-text">
        <h2>{data.name}</h2>
        <h3>{data.title}</h3>
        <p>{data.description}</p>
      </div>
      <div className="content-img">
        {data.images && data.images[0] && <img src={data.images[0].url} alt="" />}
      </div>
      </div>
    </div>
  );
}

export default ContentItem;
