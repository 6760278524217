import * as React from "react";
import Slider from "react-slick";
function HomeListBrands({ brands = [] }) {
  const setting = {
    slidesToShow: 3,
    autoplay: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  }
  return (
    <Slider {...setting} className="brand-slik">
      {brands.map((content, i) => (
        <li key={i}>
          <a href={content.href}>
            <img src={content.url} alt="" />
          </a>
        </li>
      ))}
    </Slider>
  );
}

export default HomeListBrands;
