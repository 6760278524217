import * as React from "react";
function HomeListAchievements({ data }) {
  return (
    <>
      {data.map((content, i) => (
        <li key={i}>
          <h4>
            <span>{content.number}</span> {content.name}
          </h4>
          <p>{content.content}</p>
        </li>
      ))}
    </>
  );
}
export default HomeListAchievements;
