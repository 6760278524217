import React, { useEffect, useState } from "react";
import "../css/jobs.css";
import ContactBot from "../components/ContactBot";
import { useSelector } from "react-redux";
import * as DOMPurify from "dompurify";
import { api } from "../utils/api";
import Slide from "../components/SlideBot";

function Job() {
  const [job, setJob] = useState({ jobData: { items: [] } });
  const [internalArticle, setInternalArticle] = useState({
    internalArticleItems: [],
  });
  const [post , setPost] = useState([])
  useEffect(() => {
    api({ path: "/page-default/career" })
      .then((r) => r.json())
      .then((r) => {
        if (r.success && r.data) {
          console.log(r.data.sections);
          let data = r.data.sections || [];
          let job = data.find((d) => d.type === "job");
          let iArticle = data.find((d) => d.type === "internal_article");
          if (job) setJob(job);
          if (iArticle) setInternalArticle(iArticle);
        }
      });
      api({path: '/article'}).then(r => r.json()).then(r => {
        console.log(1111, r.data)
        if(r.success) setPost(r.data)
      })
  }, []);
  return (
    <main id="main" className="content-job">
      <section className="info-job">
        <div className="container">
          <div className="box-info-job">
            <div className="content-text">
              <h1>Specialist copywriter</h1>
              <p>
                Astronet Media is a global company with thousands of employees
                around the world.
              </p>
            </div>
            <div className="btn-apply">
              <a href="#" className="btn">
                apply now
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="list-description">
        <div className="container">
          <div className="box-list-description">
            <div className="item">
              <div className="title-list active">
                <h3>Benefits</h3>
                <img
                  className="togge-arrow"
                  src="/images/job/Polygon.svg"
                  alt="arrow"
                />
              </div>
              <div
                className="des-list"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(job.jobData.benefits),
                }}
              ></div>
            </div>
            <div className="item">
              <div className="title-list">
                <h3>Responsibility</h3>
                <img
                  className="togge-arrow"
                  src="/images/job/Polygon.svg"
                  alt="arrow"
                />
              </div>
              <div
                className="des-list"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(job.jobData.responsibility),
                }}
              ></div>
            </div>
            <div className="item">
              <div className="title-list">
                <h3>Benefits</h3>
                <img
                  className="togge-arrow"
                  src="/images/job/Polygon.svg"
                  alt="arrow"
                />
              </div>
              <div
                className="des-list"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(job.jobData.introduction),
                }}
              ></div>
            </div>
          </div>
        </div>
      </section>
      {internalArticle && <Slide data={internalArticle} items={post} />}
      <ContactBot />
    </main>
  );
}

export default Job;
