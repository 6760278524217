import React, { useState } from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Career from "./pages/Career";
import Contact from "./pages/Contact";
import Job from "./pages/Job";
import Header from "./components/header.jsx";
import Footer from "./components/footer.jsx";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setMenu,setCopyrightText } from "./redux";
import { api } from "./utils/api";
import ChildPage from "./pages/ChildPage";

function App() {
  const dispatch = useDispatch();
  const [logo, setLogo] = useState('/images/logo.svg')
  const [logoFooter, setLogoFooter] = useState('/images/logo.svg')

  useEffect(() => {
    api({path: '/header-footer'}).then((res) => res.json())
      .then((res) => {
        if (res.data && res.data[0]) {
          dispatch(setMenu(res.data[0]));
          dispatch(setCopyrightText(res.data[0].copyrightText))
        }
      });
    api({path: '/config?fields=logo,logoFooter'}).then((res) => res.json())
    .then((res) => {
      if(res.success && res.data)
        {
          setLogo(res.data[0].logo)
          setLogoFooter(res.data[0].logoFooter)
        }
    });
  }, []);

  return (
    <>
      <Header logo={logo}></Header>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/article/:slug" component={About} />
          <Route exact path="/about" component={About} />
          <Route exact path="/career" component={Career} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/job" component={Job} />
          <Route path="/:page" component={ChildPage} />
        </Switch>
      </Router>
      <Footer logo={logoFooter}></Footer>
    </>
  );
}

export default App;
