import * as React from 'react';
import { useState } from 'react';
import { useEffect  } from 'react';
const aboutOfficeData = [{link:'#', nameAddress: 'North America', title:'Red Ventures HQ', address:'1101 Red Ventures Drive, Fort Mill, SC - 29707 (704 ) 971-2300'},{link:'#', nameAddress: 'North America', title:'Red Ventures HQ', address:'1101 Red Ventures Drive, Fort Mill, SC - 29707 (704 ) 971-2300'},{link:'#', nameAddress: 'North America', title:'Red Ventures HQ', address:'1101 Red Ventures Drive, Fort Mill, SC - 29707 (704 ) 971-2300'}]
function AboutOffice({data = []}) {
  return (
    <>
        {
        data.map((content, i) =>  
        <li key={i}>
            <h4><a href={content.link}>{content.country}</a> {content.other}</h4>
            <p>{content.fullAddress}</p>
        </li>)
        } 
    </>
  );
}

export default AboutOffice;