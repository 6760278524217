import { createSlice } from "@reduxjs/toolkit";
import en from '../lang/en.json'
import vi from '../lang/vi.json'
const initialState = {
  lang: 'vi',
  translate: {
    'vi': vi,
    'en' : en 
  },
  mainMenu: [
    { href: "#", label: "About Us" },
    { href: "#", label: "Career" },
    { href: "#", label: "Talk to Us" },
  ],
  footerMenu:[
    { href: "#", label: "Privacy Policy" },
    { href: "#", label: "Term of Service" },
  ],
  copyrightText: "1"
};

const reduxSlice = createSlice({
  name: "astronet",
  initialState,
  reducers: {
    setMenu: (state, action) => {
        state.mainMenu = action.payload.mainMenu
        state.footerMenu = action.payload.footerMenu
    },
    setCopyrightText: (state, action) => {
      state.copyrightText = action.payload
    },
    setLang : (state, action) => {
      state.lang = action.payload
    }
  },
});

export const { setMenu, setCopyrightText, setLang} = reduxSlice.actions;

export default reduxSlice.reducer;
