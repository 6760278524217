import * as React from "react";

function CareerStories({ items }) {
  return (
    <>
      {items.map((content, i) => (
        <div key={i} className="content-item">
          <img src={content.icon} alt="" />
          <h3>{content.name}</h3>
          <p>{content.description}</p>
        </div>
      ))}
    </>
  );
}

export default CareerStories;
